//!. Domain Name for generating merchant shop link
//2. Stripe return url
//3. Stripe_pk

//dev-tekorero
// const login_url = "https://develop.tekorero.com/shopper-crm-merchant";
// const base_url = "https://develop.tekorero.com/shopper-crm-merchant";

//UAT test
// const login_url = "https://dev.shoppr.pro";
// const base_url = "https://dev.shoppr.pro";

//Production 
const login_url = "https://prod.shoppr.pro";
const base_url = "https://prod.shoppr.pro";

export { base_url, login_url };
// master:production
// alpha-demo
// dev-Test
